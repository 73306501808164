import React, {Component} from 'react'
import SignupForm from '../../components/SignupForm'

export default class signupnow extends Component {
  render() {
    return (
      <div className="pt-16  th-home--mobiletop" style={{marginTop: '4rem'}}>
        <div className="container grid-lg ">
          <div className="text-center">
            <div className="h3 th-font-av th-home--mobilefonth3 ">It's easy to get started, and it's free!</div>
            <div className="p th-home--mobilefonth4 th-home--padmob text-dark pt-2 pb-4" style={{fontSize: '1.3em'}}>
              Impressive Listing Management tools, perfect for your current workflow
            </div>
          </div>
        </div>
        <div class="container grid-sm">
          <SignupForm size="md" />
        </div>
        <div
          className=" container grid-lg th-home--section col-gapless th-home--padmob"
          style={{
            paddingBottom: '8em',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1em',
          }}
        />
      </div>
    )
  }
}
