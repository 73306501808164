import React, {Component} from 'react'

export default class Featuresindex extends Component {
  render() {
    return (
      <div className="">
        <div className="  text-center pt-16" style={{}}>
          {/* DESKTOP */}
          <div className="container grid-xl  th-home--mobilehide  pt-4 pb-0">
            <div className="h2 th-home--mobilefonth2 th-home--padmob text-dark" style={{paddingBottom: '0rem'}}>
              Listing management from pre-market to offer-accepted
            </div>
          </div>
          <div className="container grid-xl  text-dark th-home--mobilehide  tabcon">
            <div className=" tabs-container2  th-home--features">
              {/* <label for="offers" style={{fontSize: '.8rem'}}>
                {' '}
                Manage Offers & Closing Services
              </label>
              <label for="documents" style={{fontSize: '.8rem'}}>
                {' '}
                Form Filling & Document Management
              </label>

              <label for="list" style={{fontSize: '.8rem'}}>
                {' '}
                Private/Public Listing Information Packages{' '}
              </label>
              <label for="reports" style={{fontSize: '.8rem'}}>
                {' '}
                Detailed Buyer Interest Reports
              </label> */}
            </div>

            <div className="tabs-content-wrap container grid-xl">
              {/*  Form Filling & Document Management */}
              <input name="tab" id="documents" type="radio" checked />
              <div class="tab-content2">
                <div id="documents" className="  ">
                  <div className=" text-dark th-home--mobilehide ">
                    <div className=" tabs-container3 py-0 ">
                      <label for="offers" style={{fontSize: '.8rem'}}>
                        Manage Offers & Closing Services
                      </label>
                      <label for="documents" style={{fontSize: '.8rem', borderBottom: '2px solid #273753'}}>
                        Form Filling & Document Management
                      </label>
                      <label for="list" style={{fontSize: '.8rem'}}>
                        Private/Public Listing Information Packages
                      </label>
                      <label for="reports" style={{fontSize: '.8rem'}}>
                        Detailed Buyer Interest Reports
                      </label>
                    </div>
                  </div>
                  <div className="container grid-xl th-home--padhid pb-4 tabs-line2"></div>

                  <div className=" container grid-xl px-8 pt-12">
                    <div class="columns ">
                      <div class="column  col-6  bg-transparent text-center container grid-md " style={{}}>
                        <img
                          className=" img-responsive container grid-xl text-left"
                          src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/Documents+Final+Features+HLM+-3.png"
                          alt="HomeLight Listing Management (HLM)"
                          width="500"
                          height="auto"
                        />
                      </div>
                      <div class=" column   col-6   bg-transparent th-home--mobilefonth1 pt-4 pb-2 " style={{paddingRight: '2rem'}}>
                        <div className="h5  text-dark  text-left pb-2">Manage, Store, and View Activity on Documents</div>
                        <div className="p th-home--mobilefonthp  pb-8  text-left">
                          Need to split a large document into smaller files, re-order pages, or redact private info? You have access to all of the powerful PDF
                          manipulation tools you need without ever opening Adobe Acrobat. Create a private or public Listing Information Package and share it
                          with interested buyer parties.
                        </div>

                        <div className="h5  text-dark  text-left pb-2">Seller Disclosures & Form Filling</div>
                        <div className="p th-home--mobilefonthp  pb-8  text-left">
                          Faster, Easier, & Safer Disclosures. Easy form filling for every disclosure doc you're already using. HLM makes it seamless and
                          straightforward for your clients to complete seller disclosures. Prevent seller mistakes, limit agent liability, and save a ton of
                          time! Best yet, <u>it’s free</u> to use.
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
              {/* Form Filling & Document Management */}

              {/* Listing information packages */}
              <input name="tab" id="list" type="radio" checked />
              <div class="tab-content2">
                <div id="list" className=" ">
                  <div className=" text-dark th-home--mobilehide ">
                    <div className=" tabs-container3 py-0 ">
                      <label for="offers" style={{fontSize: '.8rem'}}>
                        Manage Offers & Closing Services
                      </label>
                      <label for="documents" style={{fontSize: '.8rem'}}>
                        Form Filling & Document Management
                      </label>
                      <label for="list" style={{fontSize: '.8rem', borderBottom: '2px solid #273753'}}>
                        Private/Public Listing Information Packages
                      </label>
                      <label for="reports" style={{fontSize: '.8rem'}}>
                        Detailed Buyer Interest Reports
                      </label>
                    </div>
                  </div>
                  <div className="container grid-xl th-home--padhid pb-4 tabs-line2"></div>

                  <div id="list" className=" container grid-xl px-8 pt-12">
                    <div class="columns ">
                      <div class="column  col-6  bg-transparent text-center container grid-md " style={{}}>
                        <img
                          className=" img-responsive container grid-xl text-left"
                          src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/Listing+Features+HLM+header4.png"
                          alt="HomeLight Listing Management (HLM)"
                          width="500"
                          height="auto"
                        />
                      </div>
                      <div class=" column   col-6   bg-transparent th-home--mobilefonth1 pt-4 pb-2 " style={{paddingRight: '2rem'}}>
                        <div className="h5  text-dark  text-left pb-2">Private/Public Listing Information Packages</div>
                        <div className="p th-home--mobilefonthp text-left">
                          Create digital Listing Information Packages that are easy to share and track viewership activity. Either private or public, a “Listing
                          Information Package” contains the supplemental documents that buyers won't find on real estate portals like Zillow.
                        </div>
                        <div className="p th-home--mobilefonthp pt-2 pb-8  text-left">
                          HLM keeps your listing-info-package secure. You'll decide who deserves access, and your packet will never end up on google.
                        </div>

                        <div className="h5  text-dark  text-left pb-2">Listing Team Shared Access</div>
                        <div className="p th-home--mobilefonthp  pb-8  text-left">
                          HomeLight Listing Management is built for teams. Trusted by Transaction Coordinators, Assistants, Realtors, Brokers, & Brokerages, HLM
                          makes it easy for you to invite your team to manage, store, and monitor viewer activity on listing documents - in one secure location.
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
              {/* Listing information packages */}

              {/*  Detailed Buyer Interest Reports*/}
              <input name="tab" id="reports" type="radio" checked />
              <div class="tab-content2">
                <div id="reports" className=" ">
                  <div className=" text-dark th-home--mobilehide ">
                    <div className=" tabs-container3 py-0 ">
                      <label for="offers" style={{fontSize: '.8rem'}}>
                        Manage Offers & Closing Services
                      </label>
                      <label for="documents" style={{fontSize: '.8rem'}}>
                        Form Filling & Document Management
                      </label>
                      <label for="list" style={{fontSize: '.8rem'}}>
                        Private/Public Listing Information Packages
                      </label>
                      <label for="reports" style={{fontSize: '.8rem', borderBottom: '2px solid #273753'}}>
                        Detailed Buyer Interest Reports
                      </label>
                    </div>
                  </div>
                  <div className="container grid-xl th-home--padhid pb-4 tabs-line2"></div>

                  <div id="reports" className=" container grid-xl px-8 pt-12">
                    <div class="columns ">
                      <div class="column  col-6  bg-transparent text-center container grid-md " style={{}}>
                        <img
                          className=" img-responsive container grid-xl text-left"
                          src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/activity+header+features+HLM+-+final+2.png"
                          alt="HomeLight Listing Management (HLM)"
                          width="500"
                          height="auto"
                        />
                      </div>
                      <div class=" column   col-6   bg-transparent th-home--mobilefonth1 pt-4 pb-2 " style={{paddingRight: '2rem'}}>
                        <div className="h5  text-dark  text-left pb-2">PDFs and email can't track viewership activity</div>
                        <div className="p th-home--mobilefonthp  pb-8  text-left">
                          Email wasn't designed for large attachments, and it won't let you track the activity for each viewer. With HLM's Listing Information
                          Packages, you and your team can view Buyer Interest Reports explaining the: <u>open</u>, <u>read</u>, and <u>%-read</u> of each
                          document by each buyer party. You can also share the Buyer Interest Reports with your seller.
                        </div>

                        <div className="h5  text-dark  text-left pb-2">View a full timeline and get instant notifications</div>
                        <div className="p th-home--mobilefonthp   text-left">You can view and download an entire timeline of viewership activity.</div>
                        <div className="p th-home--mobilefonthp  pb-8 pt-2 text-left">
                          Email, text, and iOS App notifications keep you up to date on your listing interest in real-time. Provide the proper attention to the
                          most interested parties and start seeing offers coming well before they arrive.
                        </div>
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
              {/*  Detailed Buyer Interest Reports */}

              {/* MANAGE OFFERS */}
              <input name="tab" id="offers" type="radio" checked />
              <div class="tab-content2">
                <div id="offers" className="  ">
                  <div className=" text-dark th-home--mobilehide ">
                    <div className=" tabs-container3 py-0 ">
                      <label for="offers" style={{fontSize: '.8rem', borderBottom: '2px solid #273753'}}>
                        Manage Offers & Closing Services
                      </label>
                      <label for="documents" style={{fontSize: '.8rem'}}>
                        Form Filling & Document Management
                      </label>
                      <label for="list" style={{fontSize: '.8rem'}}>
                        Private/Public Listing Information Packages
                      </label>
                      <label for="reports" style={{fontSize: '.8rem'}}>
                        Detailed Buyer Interest Reports
                      </label>
                    </div>
                  </div>
                  <div className="container grid-xl th-home--padhid pb-4 tabs-line2"></div>

                  <div className=" container grid-xl px-8 pt-12">
                    <div class="columns ">
                      <div class="column  col-6  bg-transparent text-center  " style={{}}>
                        <img
                          className=" img-responsive container grid-xl text-left"
                          src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/Offeres+HLM+Features+Final+2.png"
                          alt="HomeLight Listing Management (HLM)"
                          width="500"
                          height="auto"
                        />
                      </div>
                      <div class=" column   col-6   bg-transparent th-home--mobilefonth1 pt-4 pb-2 " style={{paddingRight: '2rem'}}>
                        <div className="h5  text-dark  text-left pb-2">Send, Receive, Respond and Manage Offers</div>
                        <div className="p th-home--mobilefonthp  pb-8  text-left">
                          Receive all of your offers in one secure, online location. HLM will automatically organize every offer into a beautiful dashboard
                          (pictured left) that compares the terms side-by-side. This dashboard is sharable with your seller.
                        </div>

                        <div className="h5  text-dark  text-left pb-2">Give clients a great closing with HomeLight</div>
                        <div className="p th-home--mobilefonthp  pb-8  text-left" style={{paddingRight: '2rem'}}>
                          A modern escrow experience designed for top agents, their teams, and their clients. Enjoy a single and secure place where all closing
                          documents are easily accessible, anytime. Our industry-leading security coupled with automated notifications keeps everyone on track
                          so that you can give your client a great closing experience every time. <a href="/agents">— Learn More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* MANAGE OFFERS */}
            </div>
          </div>
          {/* SIGN UP NOW BUTTON */}
          <div className=" container grid-xl z-1 th-home--mobilehide">
            <div class="columns ">
              <div class="column  col-6  bg-transparent text-center  " style={{}}></div>
              <div class=" column   col-6   bg-transparent th-home--mobilefonth1 text-center  " style={{paddingRight: '5rem'}}>
                <div className="text-center z-1 pt-16">
                  <a
                    href="#signup-mod "
                    className="text-white btn btn-xl btn-primary"
                    style={{
                      borderRadius: '4px',
                      padding: '7px 80px',
                    }}>
                    Sign Up Now
                  </a>
                  <div className="modal modal-lg pt-20 text-primary signup-mods" id="signup-mod" style={{height: '100%'}}>
                    <a href="#" className=" modal-overlay text-primary" aria-label="Close" />
                    <div className="modal-container modaluni " role="document" style={{position: 'fixed', right: '0%', height: '100%'}}>
                      <a href="#" className=" text-right" aria-label="Close">
                        <i class="far fa-times-circle text-dark fa-lg"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SIGN UP NOW BUTTON */}

          {/* MOBILE */}
          {/* MOBILE */}
          {/* MOBILE */}
          {/* MOBILE */}
          {/* MOBILE */}
          {/* MOBILE */}
          {/* MOBILE */}
          <div className="  text-primary  th-home--deskhid">
            <div className="container grid-xl  th-home--mobiletop featurecont ">
              <div className="text-center pb-2">
                <div className="h2 th-home--mobilefonth2 text-dark pt-2" style={{}}>
                  Listing management
                </div>
                <div className="p th-home--mobilefontp-md text-dark pt-4 pb-12" style={{}}>
                  From pre-market to offer-accepted
                </div>
              </div>
            </div>

            {/* MANAGE OFFERS MOBILE */}
            <div className="  pb-2 pt-12  grid-md ">
              <div className="p th-font-av th-home--mobilefontp-b pt-2 " style={{color: '#003C45'}}>
                Manage Offers
              </div>
              <div className="p th-font-av th-home--mobilefontp  pb-4 " style={{color: '#003C45'}}>
                Send, receive, respond
              </div>

              <div className="p px-4 th-home--mobilefontp-sm   pb-8 " style={{color: '#003C45'}}>
                Receive all of your offers in one secure, online location. HLM will automatically organize every offer into a beautiful dashboard that compares
                the terms side-by-side.
              </div>
              <img
                className=" img-responsive container grid-sm pb-12"
                src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/Offeres+HLM+Features+Final+2.png"
                alt="HomeLight Listing Management (HLM)"
                width="500"
                height="auto"
              />

              <div className="p  th-font-av th-home--mobilefontp-b pt-8 container grid-md bg-gray" style={{color: '#003C45'}}>
                Closing Services
              </div>
              <div className="p  th-font-av th-home--mobilefontp  pb-2 container grid-md bg-gray" style={{color: '#003C45'}}>
                Exceed your clients expectations
              </div>

              <div className="p  th-home--mobilefontp-sm  px-4  pb-12  bg-gray" style={{color: '#003C45'}}>
                A modern escrow experience designed for top agents, their teams, and their clients. Enjoy a single and secure place where all closing documents
                are easily accessible, anytime. Our industry-leading security coupled with automated notifications keeps everyone on track so that you can give
                your client a great closing experience every time. <a href="/agents">— Learn More</a>
              </div>
            </div>
            {/* MANAGE OFFERS MOBILE*/}

            {/* Form Filling & Document Management MOBILE */}
            <div className="  pb-2 pt-8  grid-md ">
              <div className="p th-font-av th-home--mobilefontp-b pt-2 " style={{color: '#003C45'}}>
                Document Manager
              </div>
              <div className="p th-font-av th-home--mobilefontp  pb-4 " style={{color: '#003C45'}}>
                Manage, store, and view activity
              </div>

              <div className="p px-4 th-home--mobilefontp-sm   pb-8 " style={{color: '#003C45'}}>
                Need to split a large document into smaller files, re-order pages, or redact private info? You have access to all of the powerful PDF
                manipulation tools you need without ever opening Adobe Acrobat. Create a private or public Listing Information Package and share it with
                interested buyer parties.
              </div>
              <img
                className=" img-responsive container grid-sm pb-12"
                src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/Documents+Final+Features+HLM+-3.png"
                alt="HomeLight Listing Management (HLM)"
                width="500"
                height="auto"
              />

              <div className="p th-font-av th-home--mobilefontp-b pt-8 container grid-md bg-gray" style={{color: '#003C45'}}>
                Form Filling
              </div>
              <div className="p th-font-av th-home--mobilefontp  pb-2 container grid-md bg-gray" style={{color: '#003C45'}}>
                Seller Disclosures & AVID
              </div>

              <div className="p px-4 th-home--mobilefontp-sm container  pb-12 grid-md bg-gray" style={{color: '#003C45'}}>
                Faster, Easier, & Safer Disclosures. Easy form filling for every disclosure doc you're already using. HLM makes it seamless and straightforward
                for your clients to complete seller disclosures. Prevent seller mistakes, limit agent liability, and save a ton of time! Best yet,{' '}
                <u>it’s free</u> to use.
              </div>
            </div>
            {/* Form Filling & Document Management MOBILE*/}

            {/* LISTING INFORMATION  MOBILE */}
            <div className="  pb-2 pt-8  grid-md ">
              <div className="p th-font-av th-home--mobilefontp-b pt-2 " style={{color: '#003C45'}}>
                Listing Packages
              </div>
              <div className="p th-font-av th-home--mobilefontp  pb-4 " style={{color: '#003C45'}}>
                Private/public digital listing packages
              </div>

              <div className="p px-4 th-home--mobilefontp-sm   pb-8 " style={{color: '#003C45'}}>
                Create digital Listing Information Packages that are easy to share and track viewership activity. Either private or public, a “Listing
                Information Package” contains the supplemental documents that buyers won't find on real estate portals like Zillow.
              </div>
              <img
                className=" img-responsive container grid-sm pb-12"
                src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/Listing+Features+HLM+header4.png"
                alt="HomeLight Listing Management (HLM)"
                width="500"
                height="auto"
              />

              <div className="p th-font-av th-home--mobilefontp-b pt-8 container grid-md bg-gray" style={{color: '#003C45'}}>
                Listing Team Shared Access
              </div>
              <div className="p th-font-av th-home--mobilefontp  pb-2 container grid-md bg-gray" style={{color: '#003C45'}}>
                TCs, Assistants, Realtors & Brokers
              </div>

              <div className="p px-4 th-home--mobilefontp-sm container  pb-12 grid-md bg-gray" style={{color: '#003C45'}}>
                HomeLight Listing Management is built for teams. Trusted by Transaction Coordinators, Assistants, Realtors, Brokers, & Brokerages, HLM makes it
                easy for you to invite your team to manage, store, and monitor viewer activity on listing documents - in one secure location.
              </div>
            </div>
            {/* LISTING INFORMATION MOBILE*/}

            {/* Detailed Buyer Interest Reports  MOBILE */}
            <div className="  pb-12 pt-8  grid-md ">
              <div className="p th-font-av th-home--mobilefontp-b pt-2 " style={{color: '#003C45'}}>
                Track Viewership Activity
              </div>
              <div className="p th-font-av th-home--mobilefontp  pb-4 " style={{color: '#003C45'}}>
                PDFs and email can't do this!
              </div>

              <div className="p px-4 th-home--mobilefontp-sm   pb-8 " style={{color: '#003C45'}}>
                Email wasn't designed for large attachments, and it won't let you track the activity for each viewer. With HLM's Listing Information Packages,
                you and your team can view Buyer Interest Reports explaining the: <u>open</u>, <u>read</u>, and <u>%-read</u> of each document by each buyer
                party. You can also share the Buyer Interest Reports with your seller.
              </div>
              <img
                className=" img-responsive container grid-sm pb-12"
                src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/hlm-mktg-site/activity+header+features+HLM+-+final+2.png"
                alt="HomeLight Listing Management (HLM)"
                width="500"
                height="auto"
              />

              <div className="p th-font-av th-home--mobilefontp-b pt-8 container grid-md bg-gray" style={{color: '#003C45'}}>
                Instant Notifications
              </div>
              <div className="p th-font-av th-home--mobilefontp  pb-2 container grid-md bg-gray" style={{color: '#003C45'}}>
                A historical timeline & real-time
              </div>

              <div className="p px-4 th-home--mobilefontp-sm container  pb-12 grid-md bg-gray" style={{color: '#003C45'}}>
                Email, text, and iOS App notifications keep you up to date on your listing interest in real-time. Provide the proper attention to the most
                interested parties and start seeing offers coming well before they arrive.
              </div>
            </div>
            {/* Detailed Buyer Interest Reports MOBILE*/}
          </div>
        </div>
      </div>
    )
  }
}
